<template>
  <div class="register-page">
    <div class="section has-background-primary has-text-white">
      <div class="container is-tablet p-t-10x has-text-centered">
        <h1 class="title is-size-3 m-b  has-text-white">
          {{ $t('views.customer.ForgotPassword.title') }}
        </h1>
        <p>{{ $t('views.customer.ForgotPassword.desc') }}</p>
      </div>
    </div>
    <div class="section">
      <div class="container is-mobile p-t-4x">
        <ForgotForm />
      </div>
    </div>
  </div>
</template>

<script>
import ForgotForm from './ForgotForm'
export default {
  name: 'ForgotPassword',
  components: { ForgotForm },
  metaInfo () {
    return {
      title: 'Reset your Password'
    }
  }
}
</script>

<style scoped>

</style>
